export class Accordeon {
	constructor(element) {
		this.element = element
		this.init()
	}

	init() {
		this.accordionType = this.getAccordeonType()
		const isMulti = this.accordionType === 'multi'

		this.container = isMulti
			? this.element.querySelector('.accord-header').parentElement
			: this.element

		if (isMulti) {
			this.initInnerAccordeons()
		} else {
			this.body = this.container.querySelector('.accordeon__body')
		}

		this.element.addEventListener('click', (e) => this.toggleAccordeon(e))
	}

	//Определяем тип аккордеона по классу
	//single (single-level): без вложенных аккордеонов
	//multi (multi-level): несколько уровней вложенности
	getAccordeonType() {
		if (this.element.classList.contains('accord-outer') || this.element.closest('.accord-outer')) {
			return 'multi'
		} else if (this.element.classList.contains('accordeon__item')) {
			return 'single'
		}

		return null
	}

	//Инициализация вложенных аккордеонов
	initInnerAccordeons() {
		const innerElements = this.container.querySelectorAll('.accord-inner__element');
		if (innerElements.length > 0) {
			innerElements.forEach(inner => new Accordeon(inner));
		}
	}

	toggleAccordeon(e) {
		e.stopPropagation()
		const isOpened = this.container.classList.contains('accord-opened') ||
			this.container.classList.contains('accordeon__item_opened')

		isOpened ? this.closeAccordeon() : this.openAccordeon()
	}

	openAccordeon() {
		if (this.accordionType === 'multi') {
			this.container.classList.remove('accord-closed')
			this.container.classList.add('accord-opened')
		} else {
			this.container.classList.toggle('accordeon__item_opened')
			if (this.body) {
				this.body.style.maxHeight = this.body.scrollHeight + 'px'
			}
		}
	}

	closeAccordeon() {
		if (this.accordionType === 'multi') {
			this.container.classList.remove('accord-opened')
			this.container.classList.add('accord-closed')
		} else {
			this.container.classList.toggle('accordeon__item_opened')
			if (this.body) {
				this.body.style.maxHeight = null
			}
		}
	}
}