export const isPaid = [
	{ utm_source: 'google', utm_campaign: '*brand*' },
	{ utm_source: 'yandex', utm_campaign: '*sb*' },
	{ utm_source: 'yandex', utm_campaign: '*brand*' },
	{ utm_source: 'yandex', utm_campaign: '*competitors*' },
	{ utm_source: 'yandex', utm_campaign: '*general*' },
	{ utm_source: 'yandex', utm_campaign: '*mk_feed*' },
	{ utm_source: 'yandex', utm_campaign: '*mk-feed*' },
	{ utm_source: 'yandex', utm_medium: 'cpc' },
	{ utm_source: 'google', utm_medium: 'cpc' },
	{ utm_source: 'youtube' },
	{ utm_source: 'telegram' },
	{ utm_source: 'partners' },
	{ utm_source: 'infopartner' },
	{ utm_source: 'infopartners' },
	{ utm_source: 'influencer' },
	{ utm_source: 'influencers' },
	{ utm_source: 'twitch' },
	{ utm_source: 'ig-bloggers' },
	{ utm_source: 'fb-bloggers' },
	{ utm_source: 'podcast-bloggers' },
	{ utm_source: 'tw-bloggers' },
	{ utm_source: 'vk-bloggers' },
	{ utm_source: 'influensers' },
	{ utm_source: 'partner' },
	{ utm_source: 'zen' },
	{ utm_source: 'yandex.zen' },
	{ utm_source: 'yandex_zen' },
	{ utm_source: 'zen.yandex.ru' },
	{ utm_source: '+zen' },
	{ utm_source: 'tiktok', utm_medium: 'cpc' },
	{ utm_source: 'programatic' },
	{ utm_source: '*tgads*' },
	{ utm_campaign: '*tgads*' },
	{ utm_source: 'media' }
];

export const isCpa = [
	{ utm_medium: 'cpa' }
];
