//import { SFCore } from '../SFCore';
import { isPaid, isCpa } from './cpa_rules.js';

/**
 * Сохраняет в cookie параметры utm, если utm_medium=cpa
 * @return {void}
 */
export function savePaidClickParamsModule() {
	const currentUtms = this.consts.currentUrl.searchParams;
	if (currentUtms.has('utm_medium') && currentUtms.get('utm_medium') === 'cpa') {
		const cookieName = this.consts.deduplicationCookie;
		this.setCookie(cookieName, currentUtms.toString(), { expires: 30 })
	}
}

/**
 * Проверяет, является ли currentUrl-paid-каналом, т.е. есть ли в URL параметры, соответствующие
 * правилам из isPaid. Если Да, то возвращает true, иначе false.
 * @return {boolean}
 */
export function checkPayChannelModule() {
	const currentUrlParams = this.consts.currentUrl.searchParams;
	if (currentUrlParams.size === 0) {
		return false;
	}
	const filteredUtms = Array.from(currentUrlParams.keys()) // получаем ключи
		.filter(key => key.startsWith('utm_')) // фильтруем ключи, начинающиеся с 'utm_'
		.reduce((obj, key) => {
			const value = currentUrlParams.get(key); // используем метод .get() для получения значений
			if (value !== null) {
				obj[key] = value;
			}
			return obj;
		}, {});


	function isMatch(rule, data) {
		return Object.keys(rule).every(key => {
			const ruleValue = rule[key];
			const dataValue = data[key];

			if (dataValue === undefined || dataValue === null) {
				return false;
			}
			// Если правило это '*', любое значение подходит
			if (ruleValue === '*') {
				return true;
			}

			// Если правило начинается и/или заканчивается на '*'
			if (ruleValue.startsWith('*') && ruleValue.endsWith('*')) {
				const middlePart = ruleValue.slice(1, -1); // Убираем '*' с обеих сторон
				return dataValue.includes(middlePart); // Проверяем, что в середине есть подстрока
			} else if (ruleValue.startsWith('*')) {
				const endPart = ruleValue.slice(1); // Убираем '*' с начала
				return dataValue.endsWith(endPart); // Проверяем, что строка оканчивается этой частью
			} else if (ruleValue.endsWith('*')) {
				const startPart = ruleValue.slice(0, -1); // Убираем '*' с конца
				return dataValue.startsWith(startPart); // Проверяем, что строка начинается с этой части
			} else {
				return ruleValue === dataValue; // Если нет '*', просто сравниваем строки
			}
		});
	}


	return isPaid.some(rule => isMatch(rule, filteredUtms));;
}

export function checkCpaChannelModule() {
	const currentDedupCookie = new URLSearchParams(this.getCookie(this.consts.deduplicationCookie));
	if (currentDedupCookie && currentDedupCookie.has('utm_medium') && currentDedupCookie.get('utm_medium') === 'cpa') {
		return true
	}
	return false;
}

export function updateTildautmCookieModule() {
	const currentDedupCookie = new URLSearchParams(this.getCookie(this.consts.deduplicationCookie));
	const filteredDedupUtms = Array.from(currentDedupCookie.keys()) // получаем ключи
		.filter(key => key.startsWith('utm_')) // фильтруем ключи, начинающиеся с 'utm_'
		.reduce((obj, key) => {
			obj[key] = currentDedupCookie.get(key); // используем метод .get() для получения значений
			return obj;
		}, {});
	let utmString = '';
	for (const [key, value] of Object.entries(filteredDedupUtms)) {
		if (key.startsWith('utm_')) {
			utmString += `${key}=${value}|||`;
		}
	}
	this.setCookie('TILDAUTM', utmString.slice(0, -3), { expires: 30 });
}
