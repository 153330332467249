import { SFCore } from '../core/SFCore.js';

SFCore.prototype.ecommerce = SFCore.prototype.ecommerce || {};

SFCore.prototype.ecommerce.initEcommerce = function () {
	window.dataLayer = window.dataLayer || [];
}

/**
 * Метод для отправки события о покупке в Google Analytics (ECOMMERCE)
 *
 * @param {string} tildaRequestId - ID заявки в Tilda
 * @param {object} landingData - данные о курсе, полученные из Tilda
 * @param {string} brand - бренд
 */
SFCore.prototype.ecommerce.purchaseEvent = function (tildaRequestId, landingData, brand) {
	dataLayer.push({
		"ecommerce": {
			"currencyCode": "RUB",
			"purchase": {
				"actionField": {
					"id": tildaRequestId
				},
				"products": [
					{
						"id": landingData?.tag,
						"name": landingData?.full_course_name,
						"price": landingData?.price_full_basic,
						"brand": brand,
						"quantity": 1,
					}
				]
			}
		}
	});
}